export enum DescriptionEnum {
    ADMINISTRADORES = "Administradores",
    AJUSTE_BANCO_HORAS = "Ajuste de Banco de Horas",
    ALERTAS = "Notificações",
    ASSINATURA_DIGITAL = "Assinatura digital",
    ATIVIDADE = "Atividade",
    BANCO_HORA = "Banco de Hora",
    CALENDARIO = "Calendários",
    CANAL_VENDAS = "Canal de Vendas",
    CARGO = "Cargo",
    COBERTURA_FALTAS = "Cobertura de Faltas",
    COBRANCA = "Cobranças",
    CONFIGURACAO = "Abas de Configuração",
    CONFIGURACAO_NOTIFICACOES = "Configuração de Notificações",
    DADOS_CADASTRAIS = "Dados Cadastrais",
    DISPOSITIVOS = "Dispositivos",
    DOCUMENTOS = "Anexo de documentos",
    ESCALA = "Escalas",
    EXPORTACAO_DADO = "Exportação de Dados",
    FECHAMENTO_PONTO = "Fechamento de Ponto",
    FILIAL = "Filiais",
    FINANCEIRO = "Financeiro",
    FUNCIONARIO = "Colaborador",
    GESTOR = "Gestor",
    IMPORTACAO_DADOS = "Importação de Dados",
    INCONSISTENCIAS = "Inconsistências no ponto",
    INFORMACAO_PONTO = "Informações adicionais do ponto",
    JUSTIFICATIVA_EDICAO_PONTO = "Justificativas de Atraso",
    LANCAMENTO_AJUSTE = "Lançamentos de Ajuste",
    LOCAL_EXECUCAO = "Local de Execução",
    LOCAL_INTERESSE = "Locais de Interesse",
    LOCAL_TRABALHO = "Local de Trabalho",
    MENSAGEM = "Mensagem",
    TANGERINO_PRO = "Tangerino PRO",
    MOTIVO_AJUSTE = "Motivo de Ajuste",
    MOVIMENTACAO_FINANCEIRA = "Movimentação Financeira",
    ORDEM_SERVICO = "Ordens de Serviço",
    PONTO = "Ponto",
    QUESTIONARIO = "Questionário",
    REEMBOLSO = "Reembolso",
    REGRA_PONTO = "Regras de Ponto",
    RELATORIO = "Relatório",
    RELOGIO_ELETRONICO = "Relógio Eletrônico",
    INTEGRACAO = "Integração",
    FERIAS = "Módulo de Férias",
    LOGIN_SUCCESS = "Login sucesso",
    LOGIN_FAIL = "Login falha",
    GED = "Módulo GED"
}
